import React from "react"
import SEO from "../components/seo"
import Helmet from "react-helmet"


import Layout from "../components/layout"
import logo from "../images/2021PCS_logo-sm.png"

import carshowMagImg from "../images/carshow-mag.png"
import sidebarimage from "../images/MIMG_4940-330.jpg"
import sidebarposterimage from "../images/2023PCS_full_poster.webp"

import magazine from "../files/2020-Car-Show-Magazine.pdf"
import flyer from "../files/2024_PCS_Flyer.pdf"


import general_logo from "../images/general-logo.png"
import ms_jack_sparrow from "../images/ms-jack-sparrow.jpg"
import IMG_9296 from "../images/IMG_9296.jpg"
import IMG_9271 from "../images/IMG_9271.jpg"
import IMG_9304 from "../images/IMG_9304.jpg"
import IMG_9216 from "../images/IMG_9216.jpg"
import IMG_9226 from "../images/IMG_9226.jpg"
import IMG_9245 from "../images/IMG_9245.jpg"
import IMG_9194 from "../images/IMG_9194.jpg"
import IMG_9205 from "../images/IMG_9205.jpg"
import pie_show from "../images/pieshow.jpg"


// import flyer from "../files/2023-Car-Show-flyers.pdf"
// import poster from "../files/2023-Car-Show-half-sheet-flyers.pdf"
// import fullposter from "../files/2023-Full_poster.pdf"
// import { Container, Row, Col } from "react-bootstrap"

import "../fontawesome/css/all.min.css"

// //import bootstrap styles
// import "../bootstrap/css/bootstrap.min.css"

//import my custom styles
import "./index.css"

const IndexPage = () => (

  <div className="App">
    <Layout>
    <SEO></SEO>
      <div className="container  ">

        <div className="row">
          <div className="col col-12 col-md-8">
            <div className="text-center big-lr-padding">
              <img src={general_logo} className="responsive max-50" />
              <h1 className="mt-4">See you Aug. 30, 2025</h1>
              <h2>for the 21th Pardeeville Car Show</h2>
          
               <p className="mt-3">Follow us on <a href="https://www.instagram.com/pardeevillecarshow/"><i class="fab fa-instagram fa-lg"></i> Instagram</a> account for Photos and Dad Jokes.</p>
              <p className="mt-3">Tell us if you are going to the event on <a href="https://www.facebook.com/events/1156910479277134/"><i class="fab fa-facebook fa-lg"></i> Facebook.</a></p>
             
             <p className="mt-3">
              <b>Please note:</b> The Pardeeville Community Car and Tractor Show does not offer pre-registration. All payments, cash only, will be collected on the day of the event on the event grounds. We will never contact you online asking for payment.
             </p>
             
                 <h3 className="mt-4">From 2024: With 960 registered vehicles in the park, we are going to call it a success!</h3>
            <p>
              It is with heartfelt gratitude and humbleness that we wish to thank everyone who attended and/or had a part in making the 20th Annual Pardeeville Community Car &amp; Truck Show a success this year. Each person from the prayers on our
              behalf, to
              baking of homemade pies, to working on the grounds at the various departments such as concessions, judging, parking, to donating items for the Silent Auction, to giving of your time and/or monetary donations is appreciated more than our
              words can
              express.
            </p>
                 <p>
             <a href={flyer} className="btn btn-warning" target="_blank" rel="noopener noreferrer">Download the 2024 Flyer</a>
              </p>
             
                   <h2 className="mt-4"> See the Car Show from the air</h2>
                   <div className="container-iframe">
              <iframe className="responsive-iframe" width="560" height="315" src="https://www.youtube.com/embed/1r_Ws3I-gJ4?si=brmXpgHJaI_7p8eZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            </div>
          </div>


          <div className="col col-md-4 text-center mt-4 side-bar">

            <h2 className="mt-2">Largest Car Show in the area</h2>

            <img  className="responsive" src={sidebarimage} alt="19th Annual Pardeeville Community Car &amp; Truck Show Largest Car Show in the area" />
           <br/>   <br/><h4>900+ cars, food &amp; fun</h4>           
          
              
         
            <hr className="m-3" />

            <h2>2021 Car Show Magazine</h2>
            <a href={magazine} className="" target="_blank" rel="noopener noreferrer">
              <img src={carshowMagImg} className="responsive" alt="17th Annual Pardeeville Community Car &amp; Truck Show Magazine" />
            </a>
            <p>Download our full color 2020/2021 Car Show Magazine, full of stories and great info about the Show.</p>


            <p> <a href={magazine} className="btn btn-warning" target="_blank" rel="noopener noreferrer">Download the Magazine (37MB)</a></p>

            <hr className="m-3" />
            <img src={pie_show} className="responsive" alt="Pie Show" />
            <p>Come and enjoy 100s of pies from local bakers, everything from standard apple or cherry to unique and yummy specialties.</p>
          </div>
        </div>
      </div>
<Helmet>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.0/jquery.min.js"/>
  <script src="https://cdn.jsdelivr.net/npm/nanogallery2/dist/jquery.nanogallery2.min.js"/>
  <script src="/js/script.js"/>
   <link href="https://cdn.jsdelivr.net/npm/nanogallery2/dist/css/nanogallery2.min.css" rel="stylesheet" type="text/css" />
  

</Helmet>
<div className="row">
          <div className="col-md-12">
            <h1>2023 Car Gallery</h1>
            <p>Check out all 959 photos if you can.</p>
              <div id="my_nanogallery2"></div>

            <p>

            </p>
          </div>
        </div>
    </Layout >
  </div >
)
export default IndexPage
